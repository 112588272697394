import React from 'react'

import styles from './Values.module.scss'


interface Props {

}

interface State {

}

export default class Values extends React.Component<Props, State> {
  render = () => {
    return (
      <div className={styles.valuesContent}>
        
        
      </div>
    )
  }
}