export class Service {
  name: string
  iconPath: string

  constructor(name: string, iconPath: string) {
    this.name = name
    this.iconPath = iconPath
  } 
}


export default class Area {
  title: string
  services: Service[]

  constructor(title: string, services: Service[] = []) {
    this.title = title
    this.services = services
  }

  public static all = () : Area[] => {
    return [
      Area.humanToComputerContact(),
      Area.computerToComputerContact(),
      Area.computerIntelligence()
    ]
  }

  private static computerIntelligence = () : Area => {
    const services = [
      new Service("Computer Vision", '/icons/computer-vision.svg'),
      new Service("Predictive Intelligence", '/icons/predictions.svg'),
      new Service("Neural Network Design", '/icons/neural-network.svg'),
      new Service("Data Augmentation", '/icons/data-augmentation.svg'),
      new Service("Intelligent Signal Processing", '/icons/signal-processing.svg')
    ]
    return new Area("Computer Intelligence", services)
  }

  private static humanToComputerContact = () : Area => {
    const services = [
      new Service("Digital Interaction Design", '/icons/human-digital-interface.svg'),
      new Service("Hardware Interaction Design", '/icons/human-hardware-interface.svg'),
      new Service("Mobile Applications", '/icons/mobile-development.svg'),
      new Service("Web Applications", '/icons/web-development.svg'),
      new Service("Desktop Applications", '/icons/desktop-development.svg'),
      new Service("Logos and Illustrations", '/icons/illustration.svg')
    ]

    return new Area("Human ←→ Computer", services)
  }

  private static computerToComputerContact = () : Area => {
    const services: Service[] = [
      new Service("API Implementation", '/icons/api-design.svg'),
      new Service("Cloud Infrastructure", '/icons/cloud-design.svg'),
      new Service("Internet of Things", '/icons/iot.svg'),
      new Service("Embedded Linux", '/icons/embedded-linux.svg'),
      new Service("Radio Frequency Communication", '/icons/rf-design.svg'),
    ]

    return new Area("Computer ←→ Computer", services)
  }
}