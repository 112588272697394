import React from 'react'
import styles from './Services.module.scss'

import { Link } from 'react-router-dom'

interface Props {

}

interface State {

}

export default class Services extends React.Component<Props, State> {

  render = () => {
    return (
      <div className={styles.servicesListContent}>
        <h2>Computers <span className={styles.think}>think</span>.  We make them <span className={styles.thoughtful}>thoughtful.</span></h2>
        <div className={styles.actions}>
          <Link to="/about" style={{ textDecoration: 'none' }}>
            <button className={styles.learnButton}>
              About Second Synapse <span className={styles.arrow}>→</span>
            </button>
          </Link>
          <Link to="/services" style={{ textDecoration: 'none' }}>
            <button className={styles.learnButton}>
              Working With Us <span className={styles.arrow}>→</span>
            </button>
          </Link>
        </div>
      </div>
    )
  }
}