import React from 'react'

import styles from './Stats.module.scss'

interface Props {

}

interface State {

}

export default class Stats extends React.Component<Props, State> {
  render = () => {
    return (
      <div className={styles.statsContent}>
        <h1 className={styles.secondSynapse}>
          <span>Second Synapse</span>
        </h1>
        <p className={styles.descriptor}>
          We are a full-stack design and engineering agency - a rare group that can take an idea from a napkin sketch to a scaled solution.  
        </p>
        <ul>
          <li>
            <h2>Founded:</h2>
            <p>2012</p>
          </li>
          <li>
            <h2>Outside Capital:</h2>
            <p>$0</p>
          </li>
          <li>
            <h2>Products Shipped:</h2>
            <p>34</p>
          </li>
        </ul>
      </div>
    )
  }
}