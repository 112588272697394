import React from 'react'

import styles from './People.module.scss'

const Fade = require('react-reveal/Fade')

interface Props {

}

interface State {

}

class Person {
  name: string
  title: string
  content: string
  image: string

  constructor(name: string, title: string, content: string, image: string) {
    this.name = name
    this.title = title
    this.content = content
    this.image = image
  }

  static allPeople = (): Person[] => {
    return [
      new Person(
        'Dexter Weiss',
        'Founder, Principal',
        `
        <p>Dexter specializes in user experience design, machine learning, and systems architecture.  
        He’s worked on technology for companies like AT&T, FX and FXNow, Dell, Whole Foods Market, 
        and a plethora of startups.  
        From 2016 to 2019, in addition to his work at Second Synapse, 
        Dexter was the founding CTO of Washlava.  
        He took the company from a power point presentation to a full-stack technology platform deployed 
        at places like MIT and University of Florida.  
        While at Washlava he helped secure 3 patents and an investment from LG Electronics.</p>

        <p>Out of the office Dexter is passionate about woodworking, photography, and spending 
        time with his wife and young son.</p>
        `,
        '/assets/dexter.png'
      ),
      new Person(
        'Devin Murphy',
        'Embedded Systems Lead',
        `
        <p>Devin is an electrical engineer and firmware developer.  
        He’s made smart-locks, car controllers, laser safety systems, charcoal grills you can control 
        with your phone, and so much more.  Fun fact: he once scaled up a 
        sub-gigahertz triangulation system for a national rollout at CVS.  
        If you want to stick a computer in something, he’s your guy.</p>

        <p>When he’s not soldering, Devin enjoys the finer things in life — craft beer and good eats.  
        He also spends too much time preventing his dog, Emma, from eating literally everything.</p>
        `,
        '/assets/devin.png'
      ),
      new Person(
        'Ata Namvari',
        'App Lead',
        `
        <p>Ata is a die-hard coder with extensive experience in architecting and executing full-stack solutions. 
        A veteran of the startup world, he's built social networks, banking solutions, and mobile platforms. 
        In 2014, Ata co-founded a company that built a mobile marketing platform being used by almost every NFL and NHL team.
        Ata is an app whiz — Android, iOS, Web, and back-end.
        </p>

        <p>In his free time Ata likes to play the piano and draw cartoons. You'll frequently find him a patron of great restaurants, breweries, and live music venues.</p>
        `,
        '/assets/ata.png'
      )
    ]
  }
}

export default class People extends React.Component<Props, State> {

  people: Person[] = Person.allPeople()

  render = () => {
    return (
      <div className={styles.peopleContent}>
        <h2><span>Leadership</span></h2>
        {this.people.map((person: Person, index: number) => {
          return (
            <Fade key={index}>
              <div className={styles.personContent}>
                <div className={styles.personImage}>
                  <img src={person.image} alt={person.name} />
                  <p>
                    {person.name} | <span className={styles.title}>{person.title}</span>
                  </p>
                </div>
                <div className={styles.personText}>
                  <p dangerouslySetInnerHTML={{__html: person.content}} />
                </div>
              </div>
            </Fade>
          )
        })}
      </div>
    )
  }
}