import React from 'react';
import './App.css';

import { Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom'
import { useEffect } from "react";

import Main from '../pages/main/Main'
import About from '../pages/about/About'
import Services from '../pages/services/Services'

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <div className="content">
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;