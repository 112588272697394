import React from 'react'
import styles from './About.module.scss'
const Reveal = require('react-reveal/Fade');

interface Props {

}

interface State {

}

export default class About extends React.Component<Props, State> {
  render = () => {
    return (
        <div className={styles.aboutContent}>
          <Reveal>
            <h2>
              A <span className={styles.designFocused}>design-focused</span> agency 
              with <span className={styles.technicalChops}>serious technical chops</span>.
            </h2>
            <p>
              We use cutting edge tools to build next-generation digital products. 
              Small startups to Fortune 500 companies leverage our expertise to delight millions of people.
            </p>
          </Reveal>
        </div>
    )
  }
}