import React from 'react'
import styles from './Projects.module.scss'
import ProjectTile from './ProjectTile'

interface Props {

}

interface State {

}

export default class Projects extends React.Component<Props, State> {

  render = () => {
    return (
      <div className={styles.mainWrapper}>
        <h3>
          Project spotlight
          </h3>
        <div className={styles.content}>
          <ProjectTile 
            imagePath="/assets/cardless-screens.png"
            companyIconPath="/assets/cardless-color.svg"
            backgroundColor="red"
            html={
              `<p>Cardless helps brands provide their superfans with a 
              credit card experience that's fairer, smoother, and more meaningfully rewarding.</p>

         <p>We teamed up with Cardless to build and launch their iOS app and online application.</p>`
            } />
          <ProjectTile 
            imagePath="/assets/audi.png"
            companyIconPath="/assets/logo-silvercar.svg"
            backgroundColor="silver"
            html={
              `<p>Car rental doesn’t need to be difficult. Second Synapse and our partners worked with 
              Silvercar to revolutionize the car rental business.</p>

              <p>
              We helped Silvercar build a mobile app system for controlling and managing fleets of Audis. 
              With the tap of a button the doors and trunk open. Silvercar was acquired by Audi in 2016.
              </p>
              `
            }/>

          <ProjectTile 
            imagePath="/assets/grip.png"
            companyIconPath="/assets/grip&shoot-color.png"
            backgroundColor="purple"
            html={
              `
              <p>We teamed up with Grip&Shoot to build a bluetooth connected grip for medical and commercial
              photography with corresponding mobile apps.</p>
              `
            } />

          <ProjectTile 
            imagePath="/assets/strike-mobile.png"
            companyIconPath="/assets/strike-color-icon.png"
            backgroundColor="teal"
            html={
              `
              <p>With Strike, acquiring and storing crypto assets has never been simpler 
              for the institutional investor.  Strike offers the ultimate crypto wallet with ultimate security.</p>

              <p>Strike and Second Synapse came together to design, build, and deploy
              their customer-facing technologies.
              </p>
              `
            } />

          {/* <ProjectTile 
            imagePath="/assets/drone-detection.png"
            companyIconPath="/assets/persensys-logo.png"
            backgroundColor="yellow"
            html={
              `
              <p>
              Persensys, an industry leader in drone detection and mitigation technology, asked Second Synapse to 
              use Machine Learning to take their detection algorithms to the next level.
              </p>


              <p>
              We trained a computer-vision neural network to teach radio antennas to “see” drones in radio waves.</p>

              `
            } />

        <ProjectTile 
            imagePath="/assets/collectors.png"
            companyIconPath="/assets/command-color.png"
            backgroundColor="blue"
            html={
              `<p>When is it safe to pour the 50th floor of the skyscraper you’re building?  
        COMMAND Center measures the exact strength of concrete using state-of-the-art sensors and collectors.</p>

         <p>We teamed up with The Transtec Group to build the second generation of 
         their flagship concrete maturity product.
         </p>`
            }
          /> */}
        </div>
      </div>
    )
  }
}