import React from 'react'
import styles from './Quotes.module.scss'
import Quote from './model/Quote'

interface Props {

}

interface State {

}

export default class Quotes extends React.Component<Props, State> {

  private quotes: Quote[] = Quote.allQuotes()

  render = () => {
    return (
      <div className={styles.quotesContent}>
        <h2>Words from our partners</h2>
        {this.quotes.map((quote) => {
          return (
          <div className={styles.quotesWrapper}>
            <div className={styles.quote}>
              {quote.text}
            </div>
            <div className={styles.quotePerson}>
              <div className={styles.personNameAndTitle}>
                <p>{quote.name}</p>
                <p>{quote.title}</p>
              </div>
              <img src={quote.imagePath} alt={quote.name} />
            </div>
          </div>)
        })}
      </div>
    )
  }
}