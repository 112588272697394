export default class MethodologyStep {

  static allSteps = () : MethodologyStep[] => {
    return [
      new MethodologyStep(
        "We are curious",
        `
        <p>Technology solves problems.  But what's the problem?</p>

        <p>
        We work with our clients beyond a checklist of features and requirements.
        Our best work comes from constantly seeking to understand how our services will
        fit with our clients' businesses long-term.
        </p>

        `,
        '/assets/question.svg'
      ),
      new MethodologyStep(
        "We are efficient",
        `
        <p>
        Ockham said it best: “the simplest solution is most often the correct one”.  We take that to heart.
        </p>

        <p>
        We break problems down to their simplest components and craft solutions to take them head-on.
        We don't charge you for pretty power-point presentations or needless overhead.
        Our clients work directly with us and we work directly on our clients' problems.
        </p>

        `,
        '/assets/prediction.svg'
      ),
      new MethodologyStep(
        "We are passionate",
        `
        <p>
          We love this stuff.
        </p>

        <p>
          Code.  Typography.  Great interfaces.  An elegant electronics schematic.  We draw inspiration 
          from our clients and from the incredible wealth of innovation in our field.
        </p>

        `,
        '/assets/hypothesis.svg'
      ),
      new MethodologyStep(
        "We are shippers",
        `
        <p>
        We don't build stuff *in theory*.  We live for launch day.  We ship early, we ship often.
        </p>

        `,
        '/assets/testing.svg'
      ),
      new MethodologyStep(
        "We are detail oriented",
        `
        <p>
        We test our work.  We measure performance.  We peer-review code.  We sweat the small stuff.
        </p>

        `,
        '/assets/analysis.svg'
      )
    ]
  }

  name: string
  body: string
  iconPath: string

  constructor(name: string, body: string, iconPath: string) {
    this.name = name
    this.body = body
    this.iconPath = iconPath
  }
}