import React from 'react'


import NavBar, {NavBarType} from '../../nav/NavBar'
import Stats from './stats/Stats'
import People from './people/People'
import Methodology from './methodology/Methodology'

interface Props {

}

interface State {

}

export default class About extends React.Component<Props, State> {
  render = () => {
    return (
      <div>
        <NavBar type={NavBarType.LightNoTitle} />
        <Stats />
        <Methodology />
        <People />
      </div>
    )
  }
}