import React from 'react'

import { Link } from 'react-router-dom'

import styles from './NavBar.module.scss'

export enum NavBarType {
  Light,
  LightNoTitle,
  Dark,
  DarkNoTitle
}

interface Props {
  type: NavBarType
}

interface State {

}


export default class NavBar extends React.Component <Props, State> {
  render = () => {
    const { type } = this.props
    return (
      <div className={
        [styles.main, (type === NavBarType.Dark || type === NavBarType.DarkNoTitle) ? 
          styles.mainDark : styles.mainLight].join(' ')}>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <div className={styles.brand}>
            <p className={styles.logo} />
            {(type === NavBarType.Light || type === NavBarType.Dark) &&
              <h1>Second Synapse</h1>
            }
          </div>
        </Link>
        <nav>
            <Link className={styles.navLink} to="/about">About</Link>
            <Link className={styles.navLink} to="/services">Services</Link>
            <button className={styles.callToAction} onClick={() => {
              window.location.href = "mailto:hello@secondsynapse.com?emailSub='Hi, there!'"
            }}>
              Get in touch
            </button>
        </nav>
      </div>
    )
  }
}