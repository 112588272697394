import React from 'react'
import styles from './Projects.module.scss'

interface Props {
  imagePath: string
  companyIconPath: string
  html: string
  backgroundColor: string
}

interface State {

}

export default class ProjectTile extends React.Component<Props, State> {

  render = () => {
    let color: string = "clear"
    if (this.props.backgroundColor === "red") {
      color = "#FDF1F1"
    }
    else if (this.props.backgroundColor === "silver") {
      color = "#F7F7F7"
    }
    else if (this.props.backgroundColor === "blue") {
      color = "#E1E8F0"
    }
    else if (this.props.backgroundColor === "yellow") {
      color = "#FBF7E9"
    }
    else if (this.props.backgroundColor === "teal") {
      color = "#EFFFFC"
    }
    else if (this.props.backgroundColor === "purple") {
      color = "#F1EDFD"
    }
    return (
      <div className={styles.tileContent} style={{backgroundColor: color}}>
        <div className={styles.tileFade} />
        <div className={styles.fill}>
          <img className={styles.backgroundImage} src={this.props.imagePath} alt="icon" />
        </div>
        <div className={styles.tileBody}>
          <img src={this.props.companyIconPath} className={styles.logo} alt="company icon" />
          <div className={styles.tileMainText}>
            <p dangerouslySetInnerHTML={{__html: this.props.html}} />
          </div>
        </div>
      </div>
    )
  }
}