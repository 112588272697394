import React from 'react'

import Banner from './banner/Banner'
import About from './about/About'
import Projects from './projects/Projects'
import Services from './services/Services'
import ClientIcons from './clients/ClientIcons'
import NavBar, { NavBarType } from '../../nav/NavBar'

interface Props {

}

interface State {

}

export default class Main extends React.Component<Props, State> {
  render = () => {
    return (
      <div>
        <NavBar type={NavBarType.DarkNoTitle} />
        <Banner />
        <About />
        <Projects />
        <Services />
        <ClientIcons />
      </div>
    )
  }
}