import React from 'react'

import Area, {Service} from '../models/Area'
import styles from './AreaTable.module.scss'

interface Props {
  area: Area
}

interface State {

}

export default class AreaTable extends React.Component<Props, State> {
  render = () => {
    const {area} = this.props
    return (
      <div className={styles.areaTableContent}>
        <h2><span>{area.title}</span></h2>
        <div className={styles.serviceContainer}>
          {area.services.map((service: Service, index: number) => {
            return <div className={styles.service}>
              <img src={service.iconPath} alt={service.name} />
              <h3>{service.name}</h3>
            </div>
          })}
        </div>
      </div>
    )
  }
}