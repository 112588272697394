import React from 'react'
import styles from './ClientIcons.module.scss'

interface Props {

}

interface State {

}

export default class ClientIcons extends React.Component<Props, State> {
  iconPaths: string[] = [
    'whole-foods.png',
    'silvercar.png',
    'briza.png',
    'chaotic-moon.png',
    'transtec.png',
    'rocksauce.png',
    'strike-prime.png',
    'persensys.png',
    'grip&shoot.png',
    'vauto.png',
    'metalab.png',
    'cardless.png',
    'litmus-health.png',
    'menasha.png',
    'lean-mean.png',
    'shelfbucks.png'
  ]
  render = () => {
    return (
      <div className={styles.iconWrapper}>
        {this.iconPaths.map((path: string, index: number) => {
          return <img className={styles.clientIcon} src={'assets/' + path} alt={path} key={index} />
        })

        }
      </div>
    )
  }
}