import React from 'react'

import styles from './MethodologyStepView.module.scss'
import MethodologyStep from '../models/MethodologyStep'

const Reveal = require('react-reveal/Fade');

interface Props {
  methodologyStep: MethodologyStep
}

interface State {

}

export default class MethodologyStepView extends React.Component<Props, State> {
  render = () => {
    const {methodologyStep} = this.props
    return (
      <Reveal>
        <div className={styles.stepViewContent}>
          <img className={styles.stepImage} src={methodologyStep.iconPath} alt={methodologyStep.name} />
          <div className={styles.stepText}>
            <h3>
              <span>{methodologyStep.name}</span>
            </h3>
            <p dangerouslySetInnerHTML={{__html: methodologyStep.body}} />
            </div>
        </div>
      </Reveal>
    )
  }
}