import React from 'react'

import styles from './Methodology.module.scss'

import MethodologyStep from './models/MethodologyStep'
import MethodologyStepView from './components/MethodologyStepView'

interface Props {

}

interface State {

}

export default class Methodology extends React.Component<Props, State> {
  steps: MethodologyStep[] = MethodologyStep.allSteps()
  render = () => {
    return (
      <div className={styles.methodologyContent}>
        <h2>Who We Are</h2>
        {this.steps.map((step: MethodologyStep) => {
          return <MethodologyStepView methodologyStep={step} />
        })}
      </div>
    )
  }
}