export default class Quote {

  static allQuotes = () : Quote[] => {
    return [
      new Quote(
        "/assets/andrew_lawrence.png", 
        "Andrew Lawrence", 
        "CEO, Strike Protocols", 
        `
          Dexter and his team really get into our customers' heads, through 
          disciplined thinking and importantly - listening and reflecting on our business needs.  
          The result is an application of beautiful economy and functionality, the value of 
          which is immediately apprehended by our users.  Real, substantive value-add.
        `
      ),
      new Quote(
        "/assets/nathan_choe.png",
        "Nathan Choe",
        "Founder & CEO, Persensys",
        `
        Second Synapse helped us apply modern machine learning techniques in a way that 
        immediately added value to our customers.
        `
      ),
      new Quote(
        "/assets/ben_zajeski.png",
        "Ben Zajeski",
        "President, Grip and Shoot",
        `
          Working with Second Synapse has been a very positive experience. 
          We are extremely pleased with the end product, and the timeline and communication have 
          been top-notch. I felt confident laying down my design constraints and letting their creative
          team take my project to the next level. Second Synapse would challenge me on certain features and 
          suggest alternatives. I like that they treated the project as 
          their own and would only provide me with something that they themselves 
          would be proud of. Quality and Communication are most important to me and therefore 
          I’ll continue to use them in future endeavors. 
        `
      ),
      new Quote(
        "/assets/dan_rozycki.png",
        "Dan Rozycki",
        "President, Transtec Group",
        `
          Second Synapse has been excellent.  I find the tricky part of software development is all the 
          details where I assume developers think the same way I do.  They carefully check with me to 
          ensure we’re on the same page.
        `
      )
    ]
  }

  imagePath: string
  name: string
  title: string
  text: string

  constructor(imagePath: string, name: string, title: string, text: string) {
    this.imagePath = imagePath
    this.name = name
    this.title = title
    this.text = text
  }
}