import React from 'react'
import styles from './Banner.module.scss'

interface Props {

}

interface State {

}

export default class Banner extends React.Component<Props, State> {
  render = () => {
    const random = (Math.floor(Math.random() * 6)) + 1 // number between 1 and 3
    let handClass: string
    switch (random) {
      case 2:
        handClass = 'hands_2'
        break
      case 3:
        handClass = 'hands_3'
        break
      case 4:
        handClass = 'hands_4'
        break
      case 5:
        handClass = 'hands_5'
        break
      case 6:
        handClass = 'hands_6'
        break
      default:
        handClass = 'hands_1'
    }
    return (
      <div className={styles.banner}>
        <p className={[styles.tagline].join(' ')}>
          We are a team of <span className={styles.designers}>designers</span> and <span className={styles.engineers}>engineers</span> transforming 
          the way humans and businesses interface with computers.
        </p>
        <div className={[styles.image, styles.fadeIn, styles[handClass]].join(' ')}></div>
        <div className={[styles.downArrow, styles.fadeIn].join(' ')} />
      </div>
    )
  }
}