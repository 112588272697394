import React from 'react'

import styles from './Services.module.scss'
import NavBar, {NavBarType} from '../../nav/NavBar'
import Areas from './areas/Areas'
import Quotes from './quotes/Quotes'
import Values from './values/Values'

interface Props {

}

interface State {

}

export default class Services extends React.Component<Props, State> {
  render = () => {
    return (
      <div className={styles.servicesContent}>
        <NavBar type={NavBarType.LightNoTitle} />
        <Areas />
        <Quotes />
        <Values />
      </div>
    )
  }
}