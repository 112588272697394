import React from 'react'

import styles from './Areas.module.scss'
import Area from './models/Area'
import AreaTable from './components/AreaTable'


interface Props {

}

interface State {

}

export default class Areas extends React.Component<Props, State> {
  areas: Area[] = Area.all()

  render = () => {
    return (
      <div className={styles.areasContent}>
        <h1><span>Things we do:</span></h1>
        {this.areas.map((area: Area, index: number) =>{
          return <AreaTable area={area} key={area.title} />
        })}
      </div>
    )
  }
}